@use "@sparbanken-syd/sparbanken-syd-theme/variables" as spb;

@keyframes skeleton-loading {
  0% {
    background-color: #f3f3f3;
  }

  50% {
    background-color: #e5e5e5
  }

  100% {
    background-color: #f3f3f3;
  }
}

@keyframes skeleton-loading-darker {
  0% {
    background-color: #eaeaea;
  }

  50% {
    background-color: #cbcaca
  }

  100% {
    background-color: #eaeaea;
  }
}

@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes spin-clockwise {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bubble-popping {
  33% {
    background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%
  }

  50% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%
  }

  66% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%
  }
}